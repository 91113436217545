import { useState, useEffect } from 'react';

export const useWindowSize = () => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  return screenWidth;
};

import React, { useState, useEffect, useRef, FunctionComponent } from 'react';

import { LAZY_IMAGE_OBSERVER_PROPS } from '../components/Toolkit/Card/cardConstants';

//reference code
//https://stackoverflow.com/questions/63405009/react-how-do-you-lazyload-image-from-api-response

export type LazyImageProps = {
  children: any;
  Placeholder: React.ComponentType<any> | React.ElementType<any>;
  rootMargin?: {
    rootMargin: string;
  };
};

const LazyImage: FunctionComponent<LazyImageProps> = ({
  children,
  Placeholder,
  rootMargin,
}) => {
  const [shouldLoad, setShouldLoad] = useState(false);
  const placeholderRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!shouldLoad && placeholderRef.current) {
      //check if browser supports loading attribute
      if ('loading' in HTMLImageElement.prototype) {
        setShouldLoad(true);
      } else {
        //manual intersection observer for unsupported browsers
        const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
          if (intersectionRatio > 0) {
            setShouldLoad(true);
          }
        }, rootMargin ?? LAZY_IMAGE_OBSERVER_PROPS);
        observer.observe(placeholderRef.current);

        return () => observer.disconnect();
      }
    }
  }, [shouldLoad, Placeholder]);

  return <>{shouldLoad ? children : <Placeholder ref={placeholderRef} />}</>;
};

export default LazyImage;

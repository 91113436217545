import { formatUrlValueForDisplay, safeGet } from '../../helpers/utils';

import { SECTIONS } from '../../types';

const getLocationText = (filterAPI: { name: string }[]) => {
  const locationFilterObject = filterAPI.find(
    (filter) => filter.name === 'location',
  );

  let locationText;
  if (locationFilterObject == undefined) {
    return locationText;
  }

  const { values = [], currentValue = {} } = locationFilterObject as any;
  const { location = [] } = currentValue;

  const currentLocation = values.find(
    (item: any) => item.displayValue === location[0],
  );
  const locationName = currentLocation && currentLocation.displayName;

  if (locationName) {
    locationText =
      location.length > 1
        ? `${locationName} (+${location.length - 1})`
        : locationName;
  }
  return locationText;
};

const getPropertyText = (filterAPI: { name: string }[]) => {
  const propertyTypeFilterObject = filterAPI.find(
    (filter) => filter.name === 'propertyType',
  );

  const propertyTypesValues =
    propertyTypeFilterObject &&
    safeGet(propertyTypeFilterObject, ['currentValue', 'values']);
  if (
    propertyTypesValues &&
    propertyTypesValues.length === 1 &&
    propertyTypesValues[0]
  ) {
    return `${formatUrlValueForDisplay(propertyTypesValues[0])}`;
  }
  if (typeof propertyTypesValues === 'string') {
    return `${formatUrlValueForDisplay(propertyTypesValues)}`;
  }
};

const getCountryText = (filterAPI: { name: string }[]) => {
  const countryFilterObject = filterAPI.find(
    (filter) => filter.name === 'country',
  );

  const countryValues =
    countryFilterObject &&
    safeGet(countryFilterObject, ['currentValue', 'values']);
  if (countryValues && countryValues.length === 1 && countryValues[0]) {
    return `${formatUrlValueForDisplay(countryValues[0])}`;
  }
  if (typeof countryValues === 'string') {
    return `${formatUrlValueForDisplay(countryValues)}`;
  }
};

const getSeoData = (
  section: SECTIONS,
  filterAPI: any,
  numResults: string | number,
  mapView: boolean | undefined,
) => {
  const country = getCountryText(filterAPI);
  const property = getPropertyText(filterAPI);
  const locationText = mapView ? 'Map Area' : getLocationText(filterAPI);

  let metaDescription;
  switch (section) {
    case SECTIONS.BUY:
      metaDescription =
        'Find Property For Sale in Ireland from all leading estate agents on the largest property listing site in Ireland.';
      if (property) {
        metaDescription = `Find ${property} for Sale in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${
          property || 'Ireland'
        } for sale on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Property for Sale in ${locationText}. Search ${numResults}, Houses for sale and Apartments for sale in ${locationText} now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Property'} for Sale in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Properties'} for Sale in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.RECENT:
      metaDescription =
        'Find Property For Sale in Ireland from all leading estate agents on the largest property listing site in Ireland.';
      if (property) {
        metaDescription = `Find ${property} for Sale in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${
          property || 'Ireland'
        } for sale on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Property for Sale in ${locationText}. Search ${numResults}, Houses for sale and Apartments for sale in ${locationText} now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Property'} for Sale in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `Recently added properties in ${locationText || 'Ireland'}`,
      };

    case SECTIONS.RENT:
      metaDescription = `Find Property For Rent in Ireland. Search ${numResults} properties for rent in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} For Rent in ${locationText}. Search ${numResults} ${property} for rent in ${locationText} on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Property For Rent in ${locationText}. Search ${numResults} properties for rent in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Property'} to Rent in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Properties'} to Rent in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.SHARE:
      metaDescription = `Find Property to Share in Ireland. Search ${numResults} properties and find new housemates and flatmates in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} to Share in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${property} to Share in ${
          locationText || 'Ireland'
        } on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Property to Share in ${locationText}. Search ${numResults} properties and find new housemates and flatmates in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Property'} to Share in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Properties'} to Share in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.COMMERCIAL_BUY:
      metaDescription = `Find Commercial Property For Sale in Ireland. Search ${numResults} commercial properties for sale in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} for Sale in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${property} for sale on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Commercial Property For Sale in ${locationText}. Search ${numResults} commercial properties for sale in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Commercial Property'} for Sale in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Commercial Properties'} for Sale in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.COMMERCIAL_RENT:
      metaDescription = `Find Commercial Property For Rent in Ireland. Search ${numResults} commercial properties for rent in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} for Rent in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${property} for rent on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Commercial Property For Rent in ${locationText}. Search ${numResults} commercial properties for rent in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Commercial Property'} to Rent in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Commercial Properties'} to Rent in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.STUDENT_ACCOMMODATION_RENT:
      metaDescription = `Find Student Accommodation For Rent in Ireland. Search ${numResults} student accommodation for rent in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} For Rent in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${property} for Rent in ${
          locationText || 'Ireland'
        } on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Student Accommodation For Rent in ${locationText}. Search ${numResults} student accommodation for rent in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Student Accommodation'} for Rent in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Properties'} for Rent near ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.STUDENT_ACCOMMODATION_SHARE:
      metaDescription = `Find Student Accommodation To Share in Ireland. Search ${numResults} student accommodation to share in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find ${property} To Share in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${property} to Share in ${
          locationText || 'Ireland'
        } on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find Student Accommodation To Share in ${locationText}. Search ${numResults} student accommodation to share in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `${property || 'Student Accommodation'} to Share in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Properties'} to Share near ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.NEW_HOMES:
      metaDescription = `Find New Homes For Sale in Ireland from all leading estate agents and developers on the largest property listing site in Ireland on Daft.ie now.`;
      if (property) {
        metaDescription = `Find New ${property} for Sale in ${
          locationText || 'Ireland'
        }. Search ${numResults} New ${property} for sale on Daft.ie now.`;
      } else if (locationText) {
        metaDescription = `Find New Homes for Sale in ${locationText}. Search ${numResults} new homes in developments in ${locationText} on Daft.ie now.`;
      }
      return {
        metaDescription,
        metaTitle: `New ${property || 'Homes'} for Sale in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} New ${property || 'Homes'} for Sale in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.HOLIDAY_HOMES:
      return {
        metaDescription: `Find Holiday Homes for Rent in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${
          property || 'Holiday Homes'
        } for rent on Daft.ie now.`,
        metaTitle: `${property || 'Holiday Houses'} for Rent in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Holiday Homes'} for Rent in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.PARKING_BUY:
      return {
        metaDescription: `Find Parking Spaces for Sale in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${
          property || 'Parking Spaces'
        } for sale on Daft.ie now.`,
        metaTitle: `${property || 'Parking Spaces'} for Sale in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Parking Spaces'} for Sale in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.PARKING_RENT:
      return {
        metaDescription: `Find Parking Spaces to Rent in ${
          locationText || 'Ireland'
        }. Search ${numResults} ${
          property || 'Parking Spaces'
        } to rent on Daft.ie now.`,
        metaTitle: `${property || 'Parking Spaces'} to Rent in ${
          locationText || 'Ireland'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Parking Spaces'} to Rent in ${
          locationText || 'Ireland'
        }`,
      };

    case SECTIONS.INTERNATIONAL_BUY:
      return {
        metaDescription: `Find Overseas Properties for Sale in ${
          country || 'worldwide'
        }. Search ${numResults} ${
          property || 'Overseas Properties'
        } for sale on Daft.ie now.`,
        metaTitle: `${property || 'Overseas Properties'} for Sale ${
          country ? `in ${country}` : 'worldwide'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Overseas Properties'} for Sale ${
          country ? `in ${country}` : 'worldwide'
        }`,
      };

    case SECTIONS.INTERNATIONAL_RENT:
      return {
        metaDescription: `Find Overseas Properties to Rent ${
          country ? `in ${country}` : 'worldwide'
        }. Search ${numResults} ${
          property || 'Overseas Properties'
        } to rent on Daft.ie now.`,
        metaTitle: `${property || 'Overseas Properties'} to Rent ${
          country ? `in ${country}` : 'worldwide'
        } | Daft.ie`,
        h1: `${numResults} ${property || 'Overseas Properties'} to Rent ${
          country ? `in ${country}` : 'worldwide'
        }`,
      };

    default:
      return {
        metaDescription: 'Find Irish property for sale on Daft.ie',
        metaTitle: 'Irish property for sale on the leading website | Daft.ie',
        h1: '',
      };
  }
};

export default getSeoData;

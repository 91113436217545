import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  StickyButton,
} from '@dist-property-frontend/toolkit';
import styled from 'styled-components';

type SearchPageListViewStickyButtonProps = {
  toggleMapView: () => void;
  toggleMapViewButtonRef: React.RefObject<HTMLDivElement>;
};

const StickyButtonWrapper = styled.div<{ isVisible?: boolean }>`
  & > div {
    transition: transform 0.3s ease;
    transform: translateY(${({ isVisible }) => (isVisible ? '0' : '100%')});
  }
  @media ${({ theme }) => theme.toolkit.mediaQuery.medium} {
    display: none;
  }
`;

const SearchPageListViewStickyButton = (
  props: SearchPageListViewStickyButtonProps,
) => {
  const { toggleMapView, toggleMapViewButtonRef } = props;
  const [isScrolledPast, setIsScrolledPast] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    // This useEffect hook combines the Intersection Observer and scroll direction tracking.
    // The Intersection Observer checks if the reference element (e.g., the first button) is in view.
    // If it's not in view, it sets 'isScrolledPast' to true.
    // The scroll event listener tracks the current scroll position to determine if the user is scrolling up or down,
    // updating the 'isScrollingUp' state accordingly.

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsScrolledPast(!entry.isIntersecting);
      },
      { threshold: 0 },
    );

    const observeElement = () => {
      if (toggleMapViewButtonRef.current) {
        observer.observe(toggleMapViewButtonRef.current);
      }
    };

    requestAnimationFrame(observeElement);

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrollingUp(currentScrollY < lastScrollY.current);
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (toggleMapViewButtonRef.current) {
        observer.unobserve(toggleMapViewButtonRef.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StickyButtonWrapper isVisible={isScrolledPast && isScrollingUp}>
      <StickyButton>
        <Button
          buttonSize={ButtonSizes.SMALL}
          buttonType={ButtonTypes.SECONDARY}
          Icon={<Icon icon="MAPPIN" size={16} />}
          onClick={toggleMapView}
          data-testid="map-view-sticky-button"
          type="button"
        >
          Map View
        </Button>
      </StickyButton>
    </StickyButtonWrapper>
  );
};

export default SearchPageListViewStickyButton;

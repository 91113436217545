import { useCallback, useState } from 'react';

import { useForceLoginModal } from '../../../components/PropertyPage/hooks/useForceLoginModal';

export const useSaveSearch = ({
  searchId,
  saveSearch,
  toggleAlerts,
  userId,
  isSavedSearch,
}: {
  searchId?: number | null;
  isSavedSearch: boolean;
  saveSearch: (method: string) => Promise<any>;
  toggleAlerts: (id: number, alertStatus: string) => Promise<void>;
  userId: string | undefined;
}) => {
  const [checkboxValue, setCheckboxValue] = useState(true);

  const shouldShowSaveSearchModal = useCallback(() => {
    //if searchID exists, we don't show the modal
    if (searchId || isSavedSearch) {
      return false;
    }
    return true;
  }, [searchId, isSavedSearch]);

  const [
    saveSearchModalOpen,
    openSaveSearchModalForce,
    closeSaveSearchModalForce,
  ] = useForceLoginModal(
    userId,
    'saveSearchModal',
    'modalOpen',
    shouldShowSaveSearchModal,
  );

  const checkboxAttributes = {
    isChecked: checkboxValue,
    checkboxText: 'Email me when there’s a new property',
    checkboxName: 'srp-toggle-alerts',
    onChangeCheckbox: (checkboxValue: boolean) => {
      setCheckboxValue(checkboxValue);
    },
  };

  const openSaveSearchModal = async (method: string) => {
    if (method === 'remove') {
      await saveSearch(method);
      closeSaveSearchModal();
    } else {
      openSaveSearchModalForce();
    }
  };

  const handleConfirmButton = async () => {
    const alertType = checkboxValue ? 'INSTANT' : 'NONE';

    //create a new search and set alerts option
    const newSearchId = await saveSearch('create');
    toggleAlerts(newSearchId, alertType);
    closeSaveSearchModal();
  };

  const closeSaveSearchModal = () => {
    setCheckboxValue(true); //default checkbox should be checked
    closeSaveSearchModalForce();
  };

  return {
    saveSearchModalOpen,
    checkboxAttributes,
    openSaveSearchModal,
    closeSaveSearchModal,
    handleConfirmButton,
  };
};

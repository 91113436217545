import React, { useMemo } from 'react';

import { debounce } from '../../helpers/utils';
import { Property, SearchPropertyCard } from '../../types';

import { renderCard } from './SearchPage';
import { ErrorMessageContainer, Result, StyledA } from './SearchPage.styled';
import { useListingParams } from './hooks/SRPCardListingParams.hook';
import Link from 'next/link';
import { preparePropertyUnitData } from './services/propertyUnitService';
import { createCard } from '../Toolkit/Card/CardFactory';
import ErrorBoundary from '../../helpers/components/ErrorBoundary';

type SRPResultsCardsProps = {
  listings: SearchPropertyCard[];
  savedAds: {
    [index: number]: boolean;
  };
  setSelectedListing: React.Dispatch<React.SetStateAction<Property | null>>;
  toggleSavedAd: (
    event: React.MouseEvent,
    id: number,
    isSaved?: boolean,
  ) => void;
  cardTestID: string;
  cardSize?: 'SMALL';
  shouldAddMouseEvents?: boolean;
  isShowcase?: boolean;
  addSessionStorage: (id: number) => void;
};

export function SRPResultCards(props: SRPResultsCardsProps) {
  const { addSessionStorage, listings } = props;

  const { filteredListings } = useListingParams({
    isMapView: true,
    isMobile: true,
    listings,
  });

  //For the map view, we need to keep the full listing in memory
  const fullListingMap = useMemo(() => {
    return new Map(
      listings.map((listing) => [listing.listing.id, listing.listing]),
    );
  }, [listings]);
  const deboundedSetSelectedListing = debounce(props.setSelectedListing, 350);

  const getMappedFilteredListings = useMemo(() => {
    const mappedFilteredListings = filteredListings.map((response) => {
      const id = response.id;

      let SRPCard = null;

      try {
        SRPCard = renderCard(
          response,
          'baseCard',
          props.toggleSavedAd,
          props.savedAds,
        );
      } catch (error) {
        SRPCard = (
          <ErrorMessageContainer>
            Sorry, something went wrong when retrieving this ad
          </ErrorMessageContainer>
        );
      }
      const isPRSOrNewHome = !!(
        response.cardContentProps?.prsAd || response.cardContentProps?.newHomeAd
      );

      const preparedPropertyUnitData = isPRSOrNewHome
        ? preparePropertyUnitData(
            response.featuredLevelFull,
            response.cardSubunitsProps,
          )
        : null;

      const SubunitCard = isPRSOrNewHome
        ? createCard('subunit', {
            cardSubunits: preparedPropertyUnitData.cardSubunits,
            remainingSubunits: preparedPropertyUnitData.remainingSubunits,
          })
        : null;
      return (
        <Result
          data-testid={`${props.cardTestID}-${id}`}
          key={id}
          id={`${id}`}
          onClick={() => addSessionStorage(id)}
          {...(props.shouldAddMouseEvents && {
            onFocus: () => {
              deboundedSetSelectedListing(fullListingMap.get(id) as any);
            },
            onMouseOver: () => {
              deboundedSetSelectedListing(fullListingMap.get(id) as any);
            },
            onMouseLeave: () => {
              deboundedSetSelectedListing(null);
            },
          })}
        >
          <ErrorBoundary
            renderFallback={() => (
              <ErrorMessageContainer>
                Sorry, something went wrong when retrieving this ad
              </ErrorMessageContainer>
            )}
          >
            <Link href={response.seoFriendlyPath} passHref legacyBehavior>
              <StyledA>
                <SRPCard />
              </StyledA>
            </Link>
            {SubunitCard && <SubunitCard />}
          </ErrorBoundary>
        </Result>
      );
    });
    return <>{mappedFilteredListings}</>;
  }, [props.listings]);

  return <>{getMappedFilteredListings}</>;
}

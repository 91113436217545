import { AdFeatureLevelFull } from '../../../types';
import { SrpCardSubUnitUIType } from '@dist-property-frontend/toolkit';
import { getMetaProps } from '../helpers/listingMetaProps';
import {
  PreparedPropertyUnitData,
  SrpCardSubUnitPropsType,
} from './propertyUnits.types';
import { SRPCardsDataTracking } from '../helpers/dataTracking';

/**
 * Determines the maximum number of subunits to show based on the featured level for the SRP cards.
 * @param featuredLevel
 * @returns {number} - The maximum number of subunits to show.
 */
export const getMaxSubUnitsToShow = (
  featuredLevel: AdFeatureLevelFull,
): number => {
  switch (featuredLevel) {
    case AdFeatureLevelFull.PREMIER_PARTNER_FEATURED:
    case AdFeatureLevelFull.FEATURED:
      return 3;
    case AdFeatureLevelFull.PREMIER_PARTNER_PREMIUM:
    case AdFeatureLevelFull.PREMIUM:
      return 2;
    case AdFeatureLevelFull.STANDARD:
      return 1;
    default:
      return 0;
  }
};

/**
 * Prepares the SRP card subunits
 * @param cardSubUnitsProps
 * @param featuredLevel
 * @returns {SrpCardSubUnitUIType[]}
 */
export const prepareCardSubUnits = (
  cardSubUnitsProps: SrpCardSubUnitPropsType[],
  featuredLevel: AdFeatureLevelFull,
): SrpCardSubUnitUIType[] => {
  return cardSubUnitsProps.map((subUnit) => {
    const {
      image,
      price,
      numBedrooms,
      numBathrooms,
      propertyType,
      url,
      isLoadEagerly,
    } = subUnit;
    const shouldShowImage = featuredLevel !== AdFeatureLevelFull.STANDARD;

    const metaProps = getMetaProps({
      numBedrooms,
      numBathrooms,
      propertyType,
    });

    return {
      image,
      isLoadEagerly,
      shouldShowImage,
      metaProps: metaProps,
      titlePrice: price,
      url,
      dataTracking: SRPCardsDataTracking.subunit,
    };
  });
};

export const preparePropertyUnitData = (
  featuredLevelFull: AdFeatureLevelFull,
  cardSubunitsProps: SrpCardSubUnitPropsType[],
): PreparedPropertyUnitData => {
  const maxSubUnitsToShow = getMaxSubUnitsToShow(featuredLevelFull);
  const cardSubunits = prepareCardSubUnits(
    cardSubunitsProps.slice(0, maxSubUnitsToShow),
    featuredLevelFull,
  );
  const remainingSubunits = Math.max(
    cardSubunitsProps.length - maxSubUnitsToShow,
    0,
  );
  return {
    cardSubunits,
    remainingSubunits,
  };
};

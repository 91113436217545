import { AccountsAPI } from '../../api';
import { PopTart } from '../../components/Toolkit/PopTart/PopTart';
import { authorise } from '../../helpers/auth/auth';
import { GoogleAnalyticsService } from '../../services/Analytics/AnalyticsService';
import { SearchRequest } from '../../services/Filters/FilterTypes';

const googleAnalyticsService: GoogleAnalyticsService =
  new GoogleAnalyticsService();

export const createSavedSearch = async (
  search: SearchRequest,
  titleSearch: string,
  redirectBackTo: string,
) => {
  let status, savedSearchID;

  const token = await authorise(
    {
      forceLogIn: true,
      redirectBackTo,
    },
    'daft',
  );

  try {
    if (token) {
      const res = await AccountsAPI.createSavedSearch(
        token,
        search,
        titleSearch,
      );
      status = res.status;
      savedSearchID = res.savedSearchID;

      if (status !== 201) {
        PopTart.show({
          type: 'ERROR',
          message: status === 422 ? res.errors[0].message : '',
        });
      }
    }
  } finally {
    googleAnalyticsService.pushToDataLayer({
      event: 'SEARCH_INTERACTION',
      name: 'createSavedSearch',
    });

    return { status, savedSearchID };
  }
};

export const removeSavedSearch = async (
  searchId: number,
  redirectBackTo: string,
) => {
  let status;

  const token = await authorise(
    {
      forceLogIn: true,
      redirectBackTo,
    },
    'daft',
  );

  try {
    if (token) {
      const res = await AccountsAPI.deleteSavedSearch(token, searchId);
      status = res.status;

      if (status !== 200) {
        PopTart.show({
          type: 'ERROR',
        });
      }
    }
  } finally {
    googleAnalyticsService.pushToDataLayer({
      event: 'SEARCH_INTERACTION',
      name: 'deleteSavedSearch',
    });

    return status;
  }
};

import { SearchPropertyCard } from '../../../../daft/types';

export const getPropertyListingSchemaLD = (
  listings: SearchPropertyCard[],
  metaTitle: string,
) => {
  // eslint-disable-next-line no-useless-escape
  const formatPriceWithoutComma = (price: any) => price?.replace(/\,/g, '');
  const removePriceParenthesesAndCommas = (price: any) =>
    formatPriceWithoutComma(price)?.replace(/ *\([^)]*\) */g, '');

  // get all listings and map destructured values into new array of objects
  const combineAllListings = listings.map((result: SearchPropertyCard) => {
    const {
      listing: {
        price,
        id,
        title,
        numBedrooms,
        media,
        numBathrooms,
        propertyType,
        seoFriendlyPath,
      },
    } = result;

    return {
      price,
      id,
      title,
      numBedrooms,
      media,
      numBathrooms,
      propertyType,
      seoFriendlyPath,
    };
  });

  // mapping all prices from object array to be displayed in min/max price schema values
  const sortedPriceList =
    combineAllListings &&
    combineAllListings.length > 0 &&
    combineAllListings
      .filter(
        (itemPrice) =>
          itemPrice?.price?.toLowerCase() !== 'price on application', // filter out any price with 'price on application' in array
      )
      .map(
        (item) =>
          removePriceParenthesesAndCommas(item?.price)
            ?.toLowerCase()
            ?.replace('from ', '') // some prices may have from in the price, this needs to be removed
            ?.slice(1, item?.price?.length), // then remove the euro symbol from the beginning of the price string
      )
      .sort((a, b) => a - b);

  // get the min price from the array
  const getListingMinPrice = () => sortedPriceList?.[0] || null;

  // get the max price from the array
  const getListingsMaxPrice = () =>
    sortedPriceList &&
    sortedPriceList.length > 0 &&
    sortedPriceList[sortedPriceList.length - 1] &&
    sortedPriceList[sortedPriceList.length - 1];

  // remove the end of the metaTitle for use in SEO name
  const pageTitle = metaTitle.replace('| Daft.ie', '');

  // get the currency from the first array to set the correct currency in the priceCurrency schema prop
  const priceCurrency = combineAllListings[0]?.price.includes('€');

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: pageTitle,
    offers: {
      '@type': 'AggregateOffer',
      lowPrice: getListingMinPrice(),
      highPrice: getListingsMaxPrice(),
      priceCurrency: priceCurrency ? 'EUR' : 'GBP',
      availability: 'In Stock',
    },
  });
};

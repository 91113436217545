import React from 'react';

import { AccountsAPI } from '../../api';
import { PopTart } from '../../components/Toolkit/PopTart/PopTart';
import { getGatewayEndpoint } from '../../helpers/api/gatewayUtils';
import { authorise } from '../../helpers/auth/auth';
import AnalyticsService from '../../services/Analytics/AnalyticsService';

const analyticsService: AnalyticsService = new AnalyticsService(
  getGatewayEndpoint({ endpointKey: 'DAFT_API_GATEWAY' }),
);

export const toggleSavedAd = async (
  event: React.MouseEvent,
  adId: number,
  isSaved: boolean,
  redirectBackTo: string,
) => {
  event.preventDefault();
  const method = isSaved ? 'deleteSavedAd' : 'createSavedAd';

  try {
    const token = await authorise(
      {
        redirectBackTo,
        ctx: null,
        forceLogIn: true,
      },
      'daft',
    );

    if (token) {
      const res = await AccountsAPI[method](token, adId);

      if (res.status === 200 || res.status === 201) {
        return { [adId]: !isSaved };
      }

      PopTart.show({
        type: 'ERROR',
      });
    }
  } finally {
    analyticsService.pushToDataLayer({
      event: 'AD_INTERACTION',
      name: method,
    });
  }
};
